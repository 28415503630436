const validation = () => {
    const form = document.querySelector('[data-form="call-back"]');

    // валидация телефона
    const phoneInput = document.querySelector('[name="phone-number"]');

    if(phoneInput) {
        phoneInput.addEventListener('input', ()=> {
            phoneInput.value = phoneInput.value.replace (/[^0-9+-]/g, '');
        })
    }

    // отправка формы
    try{
        form.addEventListener('submit', (e) => {
            let formInput       = form.querySelectorAll('.input'),
                formTextArea    = form.querySelector('.textarea');
            
            let inputString = '';

            formInput.forEach(item => {
                inputString = `${inputString}${item.value.trim()}`;

                if(item.value.trim() === '') {
                    if(!item.parentNode.querySelector('.form-alert')) {
                        item.parentNode.insertAdjacentHTML('afterBegin', '<div class="form-alert fade-in-extention">Поле обязательное для заполнения</div>');
                    }
                } else {
                    if(item.parentNode.querySelector('.form-alert')) {
                        item.parentNode.querySelector('.form-alert').remove();
                    }
                }

            });

            inputString = `${inputString}${formTextArea.value.trim()}`;
            e.preventDefault();

            // отправляем данные
            let alertFormPopup = document.querySelectorAll('[data-form="call-back"] .form-alert');
            if(inputString != '' && !alertFormPopup.length) {
                e.preventDefault();

                let inputsNames = form.querySelectorAll('[name]');
                let formDataResultString = '';
                inputsNames.forEach(item => {
                    formDataResultString += `${item.name}=${item.value}&`;
                });

                let resultSendString = formDataResultString.replace(/\&$/, '');

                // console.log(formDataResultString);
                // console.log(resultSendString);
                // sendData(formDataResultString);

                // отправляем запрос
                let formDataSend = new XMLHttpRequest();

                formDataSend.open('POST', '/ajax/callback.php');
                formDataSend.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

                formDataSend.send(resultSendString);
                
                formDataSend.onreadystatechange = function() {
                    if(formDataSend.readyState === XMLHttpRequest.DONE && formDataSend.status === 200) {
                        // console.log(formDataSend.responseText);
                        showSuccessMessage(document.body, formDataSend.responseText);

                        formInput.forEach(item => {
                            item.value = '';
                        });

                        formTextArea.value = '';
                    };
                }

            }
        });
    }catch(e){}
}

function showSuccessMessage(itemInner, serverMessage) {

    let htmlModal = `
        <div class="modal-wrap" data-modal="success-message">
            <div class="modal modal_size-460 fade-in-extention">
                <p class="p">${serverMessage}</p>
                <div class="close-icon close-modal" data-btn="close-modal"></div>
            </div>
        </div>
    `;

    itemInner.insertAdjacentHTML('beforeend', htmlModal);

    let modalWrap   = document.querySelector('[data-modal="success-message"]'),
        modal       = document.querySelector('[data-modal="success-message"] .modal'),
        modalClose  = document.querySelector('[data-btn="close-modal"]');

    setTimeout(()=>{

        modal.classList.add('fade-out-extention');
        modal.addEventListener('animationend', ()=> {
            modalWrap.remove();
        })

    }, 2000);

    modalClose.addEventListener('click', () => {
        modalWrap.remove();
    });
}


export default validation;