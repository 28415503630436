const catalogMobOpen = () => {
    const catalogBLock = document.querySelector('[data-block="catalog"]');

    const menuItems = document.querySelectorAll('.catalog-list-item');

    catalogBLock.addEventListener('click', (e) => {
        let target = e.target;

        

        if((target.classList.contains('catalog-list-item') || target.closest('.catalog-list-item')) && window.innerWidth < 570 && !target.parentNode.classList.contains('menu-list')) {
            let parentBLock;

            if(target.classList.contains('catalog-list-item')) {
                parentBLock = target;
            } else if (target.closest('.catalog-list-item')) {
                parentBLock = target.closest('.catalog-list-item');
            }

            menuItems.forEach(item => {
                if(item != parentBLock) {
                    item.classList.remove('active')
                }
            })

            parentBLock.classList.toggle('active');
            
        }

        if(target.classList.contains('menu-list-item_close')) {
            target.closest('.catalog-list-item').classList.remove('active');
        }       

    });

}

export default catalogMobOpen;