import 'whatwg-fetch';
import 'nodelist-foreach-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import anchors from './components/anchors';
import validation from './components/validation';
import catalogMobOpen from './components/catalog-mob-open';

document.addEventListener("DOMContentLoaded", function(){
    "use strict";

    // полифил для remove
    (function() {
        var arr = [window.Element, window.CharacterData, window.DocumentType];
        var args = [];
      
        arr.forEach(function (item) {
          if (item) {
            args.push(item.prototype);
          }
        });
      
        // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
        (function (arr) {
          arr.forEach(function (item) {
            if (item.hasOwnProperty('remove')) {
              return;
            }
            Object.defineProperty(item, 'remove', {
              configurable: true,
              enumerable: true,
              writable: true,
              value: function remove() {
                this.parentNode.removeChild(this);
              }
            });
          });
        })(args);
      })();

    // полифил closest
    (function(ELEMENT) {
      ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
      ELEMENT.closest = ELEMENT.closest || function closest(selector) {
          if (!this) return null;
          if (this.matches(selector)) return this;
          if (!this.parentElement) {return null}
          else return this.parentElement.closest(selector)
        };
    }(Element.prototype))

    // якоря
    smoothscroll.polyfill();
    anchors('[data-btn="catalog"]', '[data-container="catalog"]');
    anchors('[data-btn="ftr-catalog"]', '[data-container="catalog"]');
    anchors('[data-btn="services"]', '[data-container="services"]');
    anchors('[data-btn="ftr-services"]', '[data-container="services"]');
    anchors('[data-btn="payment"]', '[data-container="payment"]');
    anchors('[data-btn="ftr-payment"]', '[data-container="payment"]');
    anchors('[data-btn="ask-question"]', '[data-container="ask-question"]');
    anchors('[data-btn="ftr-ask-question"]', '[data-container="ask-question"]');

    // валидация
    validation();

    // Открыть/закрыть моби каталог
    catalogMobOpen();

});