const anchors = (anchorSelector, containerSelector) => {
    const anchor    = document.querySelector(anchorSelector),
          content   = document.querySelector(containerSelector);
    try{
        anchor.addEventListener('click', ()=> {
            content.scrollIntoView({block: "start", behavior: "smooth"});
        });
    }catch(e){}
}

export default anchors; 